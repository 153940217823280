import { render, staticRenderFns } from "./PersonAccountable.vue?vue&type=template&id=6ec8bfbd&scoped=true&"
import script from "./PersonAccountable.vue?vue&type=script&lang=js&"
export * from "./PersonAccountable.vue?vue&type=script&lang=js&"
import style0 from "./PersonAccountable.vue?vue&type=style&index=0&id=6ec8bfbd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec8bfbd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSparkline } from 'vuetify/lib/components/VSparkline';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VCard,VCardText,VCol,VDivider,VIcon,VList,VRow,VSheet,VSparkline,VSubheader})
