<template>
  <div class="DarkWhite">
      <v-row row no-gutters>
      <v-col cols="12" lg="5">
        <v-list class="mb-3">
          <v-subheader> Items Information</v-subheader>
          <v-divider class="mt-n3"></v-divider>
        </v-list>
        <v-row>
          <v-col
          cols="12" sm="6"
          v-for="item in supply_info1"
          :key="item.text">
            <v-card
              class="d-flex flex-row pa-2 rounded-lg pt-4"
              outlined
            >
              <div
                :class="item.colors"
                class="Offset pa-2 rounded-lg d-flex align-center"
              >
                <v-icon dark large>{{ item.icon }}</v-icon>
              </div>
              <div class="d-flex flex-column">
                <div class="pa-1 ml-2 font-weight-bold text-caption text-uppercase">
                  {{ item.text }}
                </div>
                <v-divider class="ml-2"></v-divider>
                <div class="pa-1 ml-2 text-caption">
                  {{ item.counter }}
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col
           cols="12" sm="6"
           v-for="item in supply_info2"
          :key="item.text"
          >
            <v-card
              class="d-flex flex-row pa-2 rounded-lg pt-4"
              elevation="0"
              outlined
            >
              <div
                :class="item.colors"
                class="Offset pa-2 rounded-lg d-flex align-center"
              >
                <v-icon dark large>{{ item.icon }}</v-icon>
              </div>
              <div class="d-flex flex-column">
                <div class="pa-1 ml-2 font-weight-bold text-caption text-uppercase">
                  {{ item.text }}
                </div>
                <v-divider class="ml-2"></v-divider>
                <div class="pa-1 ml-2 text-caption">
                  {{ item.counter }}
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="7">
        <v-list class="mb-2">
          <v-subheader> Items Issued Statistical Information </v-subheader>
          <v-divider class="mt-n3"></v-divider>
        </v-list>
        <v-col>
          <v-card class="mx-auto mt-2" max-width="auto" elevation="0" outlined>
            <v-sheet
              class="v-sheet--offset mx-auto mb-n5"
              color="green darken-2"
              elevation="4"
              max-width="calc(100% - 32px)"
              v-if="supply_value.length > 0"
            >
              <v-sparkline
                stroke-linecap="round"
                smooth="10"
                :labels="supply_label"
                :value="supply_value"
                color="white"
                line-width="2"
                padding="16"
              ></v-sparkline>
            </v-sheet>

            <v-card-text>
              <div class="title font-weight-bold">
                Monthly Issuance of Items
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-col>
    </v-row>

    <v-list class="my-3">
      <v-subheader> Supplies Information </v-subheader>
      <v-divider class="mt-n3"></v-divider>
    </v-list>
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <v-card
          class="d-flex flex-row pa-2 rounded-lg green darken-1 darken-5 justify-center"
          elevation="0"
          tile
        >
          <div class="d-flex flex-column">
            <div
              class="
                d-flex
                justify-center
                pa-1
                ml-2
                text-h4
                white--text
                font-weight-bold
              "
            >
              {{ approved_ris }}
            </div>
            <v-divider class="white"></v-divider>
            <div
              class="
                pa-2
                text-overline
                font-weight-bold
                d-flex
                justify-center
                white--text
              "
            >
              APPROVED RIS
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-card
          class="d-flex flex-row pa-2 rounded-lg green darken-1 justify-center"
          elevation="0"
          tile
        >
          <div class="d-flex flex-column">
            <div
              class="
                d-flex
                justify-center
                pa-1
                ml-2
                text-h4
                white--text
                font-weight-bold
              "
            >
              {{ approved_icspar }}
            </div>
            <v-divider class="white"></v-divider>
            <div
              class="
                pa-2
                text-overline
                font-weight-bold
                d-flex
                justify-center
                white--text
              "
            >
              APPROVED ICS/PAR
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-card
          class="d-flex flex-row pa-2 rounded-lg green darken-1 justify-center"
          elevation="0"
          tile
        >
          <div class="d-flex flex-column">
            <div
              class="
                d-flex
                justify-center
                pa-1
                ml-2
                text-h4
                white--text
                font-weight-bold
              "
            >
              {{ approved_rs }}
            </div>
            <v-divider class="white"></v-divider>
            <div
              class="
                pa-2
                text-overline
                font-weight-bold
                d-flex
                justify-center
                white--text
              "
            >
              APPROVED RS
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-card
          class="
            d-flex
            flex-row
            pa-2
            rounded-lg
            grey darken-2
            justify-center
          "
          elevation="0"
          tile
        >
          <div class="d-flex flex-column">
            <div
              class="
                d-flex
                justify-center
                pa-1
                ml-2
                text-h4
                white--text
                font-weight-bold
              "
            >
              {{ pending_ris }}
            </div>
            <v-divider class="white"></v-divider>
            <div
              class="
                pa-2
                text-overline
                font-weight-bold
                d-flex
                justify-center
                white--text
              "
            >
              PENDING RIS
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-card
          class="d-flex flex-row pa-2 rounded-lg grey darken-2 justify-center"
          elevation="0"
          tile
        >
          <div class="d-flex flex-column">
            <div
              class="
                d-flex
                justify-center
                pa-1
                ml-2
                text-h4
                white--text
                font-weight-bold
              "
            >
              {{ pending_icspar }}
            </div>
            <v-divider class="white"></v-divider>
            <div
              class="
                pa-2
                text-overline
                font-weight-bold
                d-flex
                justify-center
                white--text
              "
            >
              PENDING ICS/PAR
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-card
          class="d-flex flex-row pa-2 rounded-lg grey darken-2 justify-center"
          elevation="0"
          tile
        >
          <div class="d-flex flex-column">
            <div
              class="
                d-flex
                justify-center
                pa-1
                ml-2
                text-h4
                white--text
                font-weight-bold
              "
            >
              {{ pending_rs }}
            </div>
            <v-divider class="white"></v-divider>
            <div
              class="
                pa-2
                text-overline
                font-weight-bold
                d-flex
                justify-center
                white--text
              "
            >
              PENDING RS
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    </div>
</template>

<script>
export default {
  props: {
    fiscalYear: null
  },
  data: () => ({
    items1: [
      {
        colors: "green darken-1",
        icon: "mdi-cash",
        text: "No. of Items",
        counter: "0",
      },
      {
        colors: "orange darken-1",
        icon: "mdi-cash-fast",
        text: "Serviceable Items",
        counter: "0",
      },
    ],
    items2: [
      {
        colors: "grey darken-1",
        icon: "mdi-cash-multiple",
        text: "Unserviceable Items",
        counter: "0",
      },
      {
        colors: "amber darken-1",
        icon: "mdi-scale-balance",
        text: "Transferred Items",
        counter: "0",
      },
    ],
    supply_label: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    supply_value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    approved_ris: 0,
    approved_icspar: 0,
    approved_rs: 0,
    pending_ris: 0,
    pending_icspar: 0,
    pending_rs: 0,
    supply_info1: [
      {
        colors: "green darken-1",
        icon: "mdi-account-details",
        text: "Items Issued",
        counter: "0",
      },
      {
        colors: "orange darken-1",
        icon: "mdi-autorenew",
        text: "Items Renewed",
        counter: "0",
      },
    ],
    supply_info2: [
      {
        colors: "amber darken-1",
        icon: "mdi-transfer",
        text: "Items Transferred",
        counter: "0",
      },
      {
        colors: "grey darken-1",
        icon: "mdi-clipboard-arrow-left",
        text: "Items Returned",
        counter: "0",
      },
    ],
  }),
  watch: {
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize(){
      if(this.fiscalYear != null){
      this.getItemIssued();
      this.getItemRenewed();
      this.getItemTransferred();
      this.getItemReturned();
      this.getApprovedRIS();
      this.getPendingRIS();
      this.getApprovedICSPAR();
      this.getPendingICSPAR();
      this.getApprovedRS();
      this.getPendingRS();
      this.itemIssuedChartByUser();
      }
    },
    getItemIssued() {
      let data = new FormData();
      data.append('FiscalYearID', this.fiscalYear)
      this.axiosCall("/dashboard/itemIssued", "POST", data).then(
        (res) => {
          this.supply_info1[0].counter = res.data.data.cnt;
        }
      );
    },
    getItemRenewed() {
      let data = new FormData();
      data.append('FiscalYearID', this.fiscalYear)
      this.axiosCall("/dashboard/itemRenewed", "POST", data).then(
        (res) => {
          this.supply_info1[1].counter = res.data.data.cnt;
        }
      );
    },
    getItemTransferred() {
      let data = new FormData();
      data.append('FiscalYearID', this.fiscalYear)
      this.axiosCall("/dashboard/itemTransferred", "POST", data).then(
        (res) => {
          this.supply_info2[0].counter = res.data.data.cnt;
        }
      );
    },
    getItemReturned() {
      let data = new FormData();
      data.append('FiscalYearID', this.fiscalYear)
      this.axiosCall("/dashboard/itemReturned", "POST", data).then(
        (res) => {
          this.supply_info2[1].counter = res.data.data.cnt;
        }
      );
    },

    getApprovedRIS() {
      let data = new FormData();
      data.append('FiscalYearID', this.fiscalYear)
      this.axiosCall("/dashboard/approvedRIS", "POST", data).then(
        (res) => {
          this.approved_ris = res.data.data.cnt;
        }
      );
    },
    getPendingRIS() {
      let data = new FormData();
      data.append('FiscalYearID', this.fiscalYear)
      this.axiosCall("/dashboard/pendingRIS", "POST", data).then(
        (res) => {
          this.pending_ris = res.data.data.cnt;
        }
      );
    },
    getApprovedICSPAR() {
      let data = new FormData();
      data.append('FiscalYearID', this.fiscalYear)
      this.axiosCall("/dashboard/approvedICSPAR", "POST", data).then(
        (res) => {
          this.approved_icspar = res.data.data.cnt;
        }
      );
    },
    getPendingICSPAR() {
      let data = new FormData();
      data.append('FiscalYearID', this.fiscalYear)
      this.axiosCall("/dashboard/pendingICSPAR", "POST", data).then(
        (res) => {
          this.pending_icspar = res.data.data.cnt;
        }
      );
    },
    getApprovedRS() {
      let data = new FormData();
      data.append('FiscalYearID', this.fiscalYear)
      this.axiosCall("/dashboard/approvedRS", "POST", data).then(
        (res) => {
          this.approved_rs = res.data.data.cnt;
        }
      );
    },
    getPendingRS() {
      let data = new FormData();
      data.append('FiscalYearID', this.fiscalYear)
      this.axiosCall("/dashboard/pendingRS", "POST", data).then(
        (res) => {
          this.pending_rs = res.data.data.cnt;
        }
      );
    },
    itemIssuedChartByUser() {
      let data = new FormData();
      data.append('FiscalYearID', this.fiscalYear)
      this.axiosCall("/dashboard/itemIssuedChartByUser", "POST", data).then((res) => {
        this.supply_label = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec",];
        this.supply_value = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        res.data.data.result.forEach((item) => {
          this.supply_label.forEach((item2, index) => {
            if(item2 == item.monthname){
              this.supply_value[index] = parseInt(this.supply_value[index]) + parseInt(item.totalcount);
            }
          });
        });
      });
    },
  },
};
</script>

<style scoped>
.Offset {
  top: -20px !important;
  position: relative !important;
}
.v-sheet--offset {
  top: -15px;
  position: relative;
}
</style>
